import loadable from '@loadable/component';
import logger from 'saddlebag-logger';

import { ACTION_TYPE, COMPONENT_NAME } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../ErrorBoundary';

import type { SearchControlsProps } from 'common-types/types/CarHireSearchControlsProps';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const SearchControlsGC = loadable(
  () =>
    import('@skyscanner-internal/global-components/car-hire-search-controls'),
);

const SearchControls = (props: SearchControlsProps) => {
  const {
    autosuggestUrl,
    cldr,
    culture,
    dropOffDateTime,
    dropOffLocation,
    enableDropOffLocation,
    filtersQueryParam,
    isProductionEnv,
    pickUpDateTime,
    pickUpLocation,
    resultsPath,
    trackingProps,
    translations,
  } = props;

  return (
    <SearchControlsGC
      logger={logger}
      culture={culture}
      strings={translations}
      cldr={cldr}
      resultsPath={resultsPath}
      trackingProps={trackingProps}
      autosuggestUrl={autosuggestUrl}
      pickUpLocation={pickUpLocation}
      pickUpDateTime={pickUpDateTime}
      dropOffLocation={dropOffLocation}
      dropOffDateTime={dropOffDateTime}
      filtersQueryParam={filtersQueryParam}
      isProductionEnv={isProductionEnv}
      enableDropOffLocation={enableDropOffLocation}
    />
  );
};

const searchControlsWrapper = (props: SearchControlsProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.CAR_HIRE_SEARCH_CONTROLS,
      })
    }
  >
    <SearchControls {...props} />
  </IntersectionObserverWrapper>
);

const SearchControlsWithErrorBoundary = withErrorBoundary(
  searchControlsWrapper,
  'car-hire-search-controls-component',
);

export default SearchControlsWithErrorBoundary;
